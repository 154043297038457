import { ref, watch, computed, reactive } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const referrals = ref([])
const filteredReferrals = ref([])

export default function useReferral() {
  // Use toast
  const toast = useToast()

  const refReferralTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, stickyColumn: true },
    { key: 'title', sortable: true },
    { key: 'description', sortable: true },
    { key: 'beneficiary', sortable: true },
    { key: 'event', sortable: true },
    { key: 'points', sortable: true },
    { key: 'startDate', sortable: true },
    { key: 'endDate', sortable: true },
    { key: 'isEnabled', label: "Status", sortable: true },
    { key: 'actions',  },
  ]
  const perPage = ref(10)
  const totalReferrals = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refReferralTable.value ? refReferralTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReferrals.value,
    }
  })

  const refetchData = () => {
    console.log("refectchData")
    refReferralTable.value.refresh()
  }
 
  const refreshData = () => {
    referrals.value = []
    fetchReferrals()
  }

  const resolveReferralRuleStatusBadge = status => {
    if (status === true) return 'success'
    if (status === false) return 'secondary'
  }

  watch([currentPage, perPage, searchQuery], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = referrals.value.filter(
    referral => referral.title.toLowerCase().includes(queryLowered) ||
    referral.code.toLowerCase().includes(queryLowered) || referral.description.toLowerCase().includes(queryLowered))
    
    filteredReferrals.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalReferrals.value = filteredData.length
    refetchData()
  })

  function setReferral(referral) {
    referrals.value = referrals.value.filter(x => x.id != referral.id);
    referrals.value.push(referral);
    referrals.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredReferrals.value = paginateArray(referrals.value, perPage.value, currentPage.value)
  }

  function removeReferral(referralId) {
    referrals.value = referrals.value.filter(x => x.id != referralId);
    referrals.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredReferrals.value = paginateArray(referrals.value, perPage.value, currentPage.value)
  }

  const fetchReferrals = () => {
    if(referrals.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.REFERRAL_RULES)
      .then(({data}) => {
        data.forEach(element => {
          if(!element.startDate) return
          let startDate = new Date(element.startDate).toISOString().slice(0,10)
          let endDate = new Date(element.endDate).toISOString().slice(0,10)
          element.startDate = startDate
          element.endDate = endDate
          // element.endDate = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`
        });
        referrals.value = data.sort((a, b) => a.updatedAt - b.updatedAt);
        filteredReferrals.value = paginateArray(referrals.value, perPage.value, currentPage.value)
        totalReferrals.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching referrals',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const addReferral = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.post(routes.REFERRAL_RULES, data)
        .then(({ data }) => {
          let startDate = new Date(data.startDate).toISOString().slice(0,10)
          let endDate = new Date(data.endDate).toISOString().slice(0,10)
          data.startDate = startDate
          data.endDate = endDate
          setReferral(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Referral added successfully`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }
  const updateReferral = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.put(routes.REFERRAL_RULES+`/${data.id}`, data)
        .then(({ data }) => {
          let startDate = new Date(data.startDate).toISOString().slice(0,10)
          let endDate = new Date(data.endDate).toISOString().slice(0,10)
          data.startDate = startDate
          data.endDate = endDate
          setReferral(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
}
  
    const deleteReferral = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.delete(routes.REFERRAL_RULES+`/${data.id}`, data)
        .then(({ data }) => {
          // removeReferral(data.id)
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }

  return {
    fetchReferrals,
    addReferral,
    updateReferral,
    deleteReferral,
    tableColumns,
    perPage,
    currentPage,
    totalReferrals,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReferralTable,

    setReferral,
    removeReferral,
    refetchData,
    refreshData,
    resolveReferralRuleStatusBadge,
    referrals: computed(() => filteredReferrals.value)
  }
}
